import React from 'react';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {OptionalChildrenProps} from '../../infrastructure/react-helpers';

import './ConfirmModal2.scss';

interface Props extends OptionalChildrenProps {
  onClose?: () => void;
  onConfirm: () => void;
  descriptionText?: string;
}

interface State {
}

export class ConfirmModal2 extends BaseComponent<Props, State> {
  render() {
    const {onClose, onConfirm, descriptionText} = this.props;

    return (
      <div className="modal fade"
           id="confirmationModal"
           tabIndex={-1}
           aria-labelledby="confirmationModal"
           aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-white p-2">
              <h3 className="modal-title m-0" id="confirmationModal">Потвърждание</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body confirm-body">
              {descriptionText}
            </div>
            <div className="modal-footer p-1">
              <button
                type="button"
                className="btn btn-md btn-warning confirmation-button"
                data-dismiss="modal"
                onClick={onClose}>
                Затвори
              </button>
              <button
                type="button"
                className="btn btn-md btn-success confirmation-button"
                data-dismiss="modal"
                onClick={onConfirm}>
                Потвърждавам
              </button>
            </div>
          </div>
        </div>
      </div>);
  }
}
